 
import styles from "./marketInsights.module.scss";
import React, { useState } from 'react';
import Desktop from "../../../../images/marketInsightsDesktop.png";
import Tablet from "../../../../images/marketInsightsTablet.png";
import Mobile from "../../../../images/marketInsightsMobile.png";
import MarketUpdate from "../../modal/marketUpdate"
import { withNamespaces } from "react-i18next"; 
const MarketInsights = props => {
  const {t} = props;
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.imageContainer}>
          <picture>
            <source media="(max-width: 768px)" sizes="100px" srcset={Mobile} />
            <source media="(max-width: 1140px)" sizes="100px" srcset={Tablet} />
            <img src={Desktop} />
          </picture>
        </div>
 
        <div className={styles.textContainer}>
          <h3>{t("Aktuelle Marktinsights jetzt kostenlos downloaden!")}</h3>
          <p>
            {t("Schrott24 steht für mehr Transparenz im Altmetallhandel!")} <br/> 
            {t("Deshalb haben wir uns dazu entschieden, unsere Marktanalysen mit Ihnen zu teilen!")}
          </p>
          <a onClick={()=>{setOpen(true)}}>{t("Jetzt downloaden")}</a>
        </div>
      </div>
 
    <MarketUpdate open={open} setOpen={setOpen} url = "https://metal.schrott24.de/l/898431/2021-03-05/4xgf"/>
 

    </div>
  );
};

export default withNamespaces()(MarketInsights);
 
